import useGetSettings from "../../api/ahoy/useGetSettings";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Box, Button, Grid, TextField} from "@mui/material";
import {useCallback} from "react";
import {SettingsApi} from "../../api/ahoy/ahoyTypes";

const Settings = () => {
    const {settings} = useGetSettings();

    const priceSchema = Yup.object().shape({
        basePrice: Yup.string().required("Base price must be set"),
        tax: Yup.string().required("Tax must be set"),
        currency: Yup.string().required("Currency must be defined"),
    });

    const settingsSchema = Yup.object().shape({
        welcomeMessage: Yup.string(),
        stripeClientKey: Yup.string(),
        googleMapsApiKey: Yup.string(),
        maxNumberOfSeats: Yup.number().min(1).max(100),
        minBoatRidePrice: priceSchema,
        ticketPrice: priceSchema,
        offeredRideTTL: Yup.string(),
        phone: Yup.string().required("Must be a valid phone number"),
    });

    const {errors, values, touched, handleChange, handleSubmit} = useFormik({
        initialValues: {
            welcomeMessage: settings?.welcomeMessage ?? "",
            stripeClientKey: settings?.stripeClientKey ?? "",
            googleMapsApiKey: settings?.googleMapsApiKey ?? "",
            maxNumberOfSeats: settings?.maxNumberOfSeats ?? 1,
            minBoatRidePrice: settings?.minBoatRidePrice ?? {
                basePrice: "",
                tax: "",
                currency: "",
            },
            ticketPrice: settings?.ticketPrice ?? {
                basePrice: "",
                tax: "",
                currency: "",
            },
            offeredRideTTL: settings?.offeredRideTTL ?? "",
            phone: settings?.phone ?? "",
        },
        enableReinitialize: true,
        validationSchema: settingsSchema,
        onSubmit: useCallback(async (values: SettingsApi) => console.log(values), [])
    });
    return (
        <Box sx={{bgcolor: 'white', m: 1, p: 2}}>
            <h1>Settings</h1>
            {settings && (<form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Dežurni telefon"
                            disabled
                            value={values.phone}
                            onChange={handleChange}
                            error={Boolean(touched.phone === true && errors.phone)}
                            helperText={touched.phone === true && errors.phone}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="number"
                            id="maxNumberOfSeats"
                            name="maxNumberOfSeats"
                            label="Maksimalni broj mjesta"
                            disabled
                            value={values.maxNumberOfSeats}
                            onChange={handleChange}
                            error={Boolean(touched.maxNumberOfSeats === true && errors.maxNumberOfSeats)}
                            helperText={touched.maxNumberOfSeats === true && errors.maxNumberOfSeats}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="maxNumberOfSeats"
                            name="maxNumberOfSeats"
                            label="Vrijeme cekanja ponude (ISO 8601)"
                            disabled
                            value={values.offeredRideTTL}
                            onChange={handleChange}
                            error={Boolean(touched.offeredRideTTL === true && errors.offeredRideTTL)}
                            helperText={touched.offeredRideTTL === true && errors.offeredRideTTL}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="minBoatRidePrice.basePrice"
                            name="minBoatRidePrice.basePrice"
                            type="number"
                            label="Minimalna cijena voznje bez PDV-a"
                            disabled
                            value={values.minBoatRidePrice.basePrice}
                            onChange={handleChange}
                            error={Boolean(touched.minBoatRidePrice?.basePrice === true && errors.minBoatRidePrice?.basePrice)}
                            helperText={touched.minBoatRidePrice?.basePrice === true && errors.minBoatRidePrice?.basePrice}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="minBoatRidePrice.tax"
                            name="minBoatRidePrice.tax"
                            type="number"
                            label="PDV"
                            disabled
                            value={values.minBoatRidePrice.tax}
                            onChange={handleChange}
                            error={Boolean(touched.minBoatRidePrice?.tax === true && errors.minBoatRidePrice?.tax)}
                            helperText={touched.minBoatRidePrice?.tax === true && errors.minBoatRidePrice?.tax}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="minBoatRidePrice.currency"
                            name="minBoatRidePrice.currency"
                            label="Valuta"
                            disabled
                            value={values.minBoatRidePrice.currency}
                            onChange={handleChange}
                            error={Boolean(touched.minBoatRidePrice?.currency === true && errors.minBoatRidePrice?.currency)}
                            helperText={touched.minBoatRidePrice?.currency === true && errors.minBoatRidePrice?.currency}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="number"
                            id="ticketPrice.basePrice"
                            name="ticketPrice.basePrice"
                            label="Cijena voznje bez PDV-a po osobi"
                            disabled
                            value={values.ticketPrice.basePrice}
                            onChange={handleChange}
                            error={Boolean(touched.ticketPrice?.basePrice === true && errors.ticketPrice?.basePrice)}
                            helperText={touched.ticketPrice?.basePrice === true && errors.ticketPrice?.basePrice}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="ticketPrice.tax"
                            name="ticketPrice.tax"
                            type="number"
                            label="PDV"
                            disabled
                            value={values.ticketPrice.tax}
                            onChange={handleChange}
                            error={Boolean(touched.ticketPrice?.tax === true && errors.ticketPrice?.tax)}
                            helperText={touched.ticketPrice?.tax === true && errors.ticketPrice?.tax}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="ticketPrice.currency"
                            name="ticketPrice.currency"
                            label="Valuta"
                            disabled
                            value={values.ticketPrice.currency}
                            onChange={handleChange}
                            error={Boolean(touched.ticketPrice?.currency === true && errors.ticketPrice?.currency)}
                            helperText={touched.ticketPrice?.currency === true && errors.ticketPrice?.currency}
                        />
                    </Grid>
                </Grid>
                {/*<Button sx={{marginTop: '1em'}} type="submit" variant="contained" color="primary">Save</Button>*/}
            </form>)}
        </Box>
    )
}

export default Settings;