import {Navigate} from "react-router-dom";
import {useAuth} from "./api/auth/useAuth";
import React from "react";

export const ProtectedRoute = ({children}: {
    children: React.ReactElement;
}) => {
    if (process.env.REACT_APP_ENFORCE_LOGIN == "false") {
        return children;
    }
    const {user} = useAuth();
    if (user != null) {
        return children;
    }
    return <Navigate to="/login" replace/>;
};
