import React, {createContext, useContext, useMemo, useState} from "react";
import {useLocalStorage} from "usehooks-ts";
import toast from "react-hot-toast";
import {ahoySignInWithEmailAndPassword, trySendPasswordResetEmail} from "../../firebaseSetup";
import {getAuth, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";

// everything here must be serializable
export interface UserInfo {
    name: string
    email: string
    abbrev: string
    role: string
}

export interface EmailAndPassword {
    email: string
    password: string
}

type AuthContextType = {
    logout: () => void
    sendPasswordResetEmail: (email: string) => Promise<void>
    authenticate: (values: EmailAndPassword) => Promise<void>
    user: UserInfo | null
    loading: boolean
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useLocalStorage<UserInfo | null>("ahoy_user", null);
    const authenticate = async (values: EmailAndPassword) => {
        try {
            setLoading(true);
            const firebaseAuth = await ahoySignInWithEmailAndPassword(values.email, values.password);
            const name = firebaseAuth.user.displayName ?? firebaseAuth.user.email ?? "N/A";
            const user = {
                name: name,
                email: firebaseAuth.user.email ?? "N/A",
                role: "Admin",
                abbrev: name.split(" ").map((s) => s.slice(0,1)).join(""),
            };
            setUser(user);
            navigate('/dashboard/map');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Login failed");
            setUser(null);
        }
    };

    const logout = async () => {
        await signOut(auth);
        setUser(null);
    };

    const sendPasswordResetEmail = async (email: string) => trySendPasswordResetEmail(email);

    const value: AuthContextType = useMemo(
        () => ({
            logout,
            sendPasswordResetEmail,
            authenticate,
            user,
            loading
        }),
        [user, loading]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
