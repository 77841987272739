import {CssBaseline} from "@mui/material";
import {StyledEngineProvider} from "@mui/material/styles";
import {FC, lazy, LazyExoticComponent, Suspense} from "react";
import {Toaster} from "react-hot-toast";
import ThemeProvider from './theme/ThemeProvider'
import {AuthProvider} from "./api/auth/useAuth";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import {ProtectedRoute} from "./ProtectedRoute";
import Settings from "./pages/settings/Settings";
import {useAuthState} from "react-firebase-hooks/auth";
import {authentication} from "./firebaseSetup";
import {Provider} from 'use-http'

//@typescript-eslint/no-explicit-any
const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
    (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props} />
        </Suspense>
    );

const LocationPage = Loadable(lazy(() => import("./pages/popular-locations/LocationDetailsPage")));
const RideDetailsPage = Loadable(lazy(() => import("./pages/rides-list/RideDetailsPage")));
const Login = Loadable(lazy(() => import("./pages/auth/login/Login")));
const ForgotPassword = Loadable(lazy(() => import("./pages/auth/forgot-password/ForgotPassword")));
const SidebarLayout = Loadable(lazy(() => import("./components/layout/sidebar-layout/SidebarLayout")));
const Dashboard = Loadable(lazy(() => import("./pages/dashboard/Dashboard")));
const Skippers = Loadable(lazy(() => import("./pages/skippers/Skippers")));
const SkipperDetailsPage = Loadable(lazy(() => import("./pages/skippers/SkipperDetailsPage")));
const Locations = Loadable(lazy(() => import("./pages/popular-locations/Locations")));
const Rides = Loadable(lazy(() => import("./pages/rides-list/RidesList")));
const Boats = Loadable(lazy(() => import("./pages/boats/Boats")));
const BoatFinanceReport = Loadable(lazy(() => import("./pages/boats/BoatFinanceReport")));
const BoatDetailsPage = Loadable(lazy(() => import("./pages/boats/BoatDetailsPage")));
const Affiliates = Loadable(lazy(() => import("./pages/affiliates/Affiliates")));
const AffiliateRidesPage = Loadable(lazy(() => import("./pages/affiliates/AffiliateRidesPage")));

const App: FC = () => {
    const toasterOptions = {
        style: {
            fontWeight: 500,
            fontFamily: "'Space grotesk', sans-serif",
        },
    };

    let options = {};
    const [user] = useAuthState(authentication);
    const baseUrl = process.env.REACT_APP_API_HOST;

    let routes = <Routes><Route path="/login" element={<Login/>}/><Route path="*" element={<Login/>}/></Routes>;
    if (user) {
        options = {
            interceptors: {
                request: async ({options}: { options: any }) => {
                    const token = await user.getIdToken();
                    options.headers.Authorization = `Bearer ${token}`;
                    return options;
                },
            },
        };
        routes = <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="/dashboard" element={<ProtectedRoute><SidebarLayout/></ProtectedRoute>}>
                <Route path="map" element={<Dashboard/>}/>
                <Route path='rides' element={<Rides/>}/>
                <Route path='rides/:id' element={<RideDetailsPage/>}/>
                <Route path="skippers" element={<Skippers/>}/>
                <Route path="skippers/:id" element={<SkipperDetailsPage/>}/>
                <Route path="boats" element={<Boats/>}/>
                <Route path="boats/:id" element={<BoatDetailsPage/>}/>
                <Route path="boats/:id/finance_report" element={<BoatFinanceReport/>}/>
                <Route path="locations" element={<Locations/>}/>
                <Route path="locations/:id" element={<LocationPage/>}/>
                <Route path="settings" element={<Settings/>}/>
                <Route path="affiliates" element={<Affiliates/>}/>
                <Route path="affiliates/:id/ride" element={<AffiliateRidesPage/>}/>
            </Route>
            <Route path="*" element={<Login/>}/>
        </Routes>
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider>
                <CssBaseline/>
                <Toaster toastOptions={toasterOptions}/>
                <BrowserRouter>
                    <Provider url={baseUrl} options={options}>
                        <AuthProvider>
                            {routes}
                        </AuthProvider>
                    </Provider>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;